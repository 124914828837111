.auth{
    min-height: 100vh;
    min-width: 100vw;
    box-sizing: border-box;
    background-image: url('/assets/images/natureforesttreesfog.jpeg');
    filter: grayscale(100);
    background-position: 50% 50%;
}

.authContainer{
    margin-top: 130px !important;
    border: 2px solid #fff;
    padding: 20px !important;
    border-radius: 10px;
    filter: grayscale(0) !important;
    backdrop-filter: blur(5px);
}
