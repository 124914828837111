.searchList:nth-child(odd){
    background: #fff;
}
.searchList:nth-child(even){
    background: #dcdada;
}

.collapse{
    :global(.ant-collapse-content-box) {
        padding: 0 !important;
    }
}